import { DOCUMENT, NgIf } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule
} from '@angular/router';
import { UtilsService } from '@dx-web/modules/shared/data-access';
import { environment } from '@dx-web/modules/shared/environments';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { NgxSonnerToaster } from 'ngx-sonner';
import { IStaticMethods } from 'preline/preline';

// Declares global interface to extend the Window object for custom static methods
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

/**
 * Root component of the application, responsible for rendering the main layout,
 * handling theme changes, and reacting to route changes.
 * It utilizes Angular's standalone component feature to include necessary dependencies.
 */
@UntilDestroy()
@Component({
  standalone: true,
  imports: [RouterModule, NgIf, AngularQueryDevtools, NgxSonnerToaster],

  selector: 'dx-web-root',
  template: `
    <!-- Main router outlet that will be replaced by the component associated with the active route -->
    <router-outlet></router-outlet>

    <!-- Conditionally renders Angular Query Devtools in non-production environments -->
    @if (!environment.production) {
      <angular-query-devtools [initialIsOpen]="false" />
    }

    <!-- Toast notifications component with configurable properties -->
    <ngx-sonner-toaster
      closeButton
      duration="5000"
      richColors
      [theme]="isLightTheme ? 'light' : 'dark'"
    />
  `
})
export class AppComponent implements OnInit {
  // Dependency injections to utilize Angular and third-party services
  private router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);
  private utilsService = inject(UtilsService);
  protected environment = environment;
  protected isLightTheme = true; // Tracks the current theme state
  private currentRouteName: string; // Stores the name of the current route

  /**
   * Initializes the component by setting the theme and subscribing to router events.
   */
  ngOnInit() {
    this.isLightTheme = this.getTheme();
    this.applyTheme();

    // Subscribe to router events to handle navigation end events
    this.router.events
      .pipe(untilDestroyed(this)) // Automatically unsubscribe to prevent memory leaks
      .subscribe((event: unknown) => {
        if (event instanceof NavigationEnd) {
          this.utilsService.routeHistory.set([
            ...this.utilsService.routeHistory(),
            event.urlAfterRedirects
          ]);
          this.setCurrentRouteName();
          setTimeout(() => {
            // window.HSStaticMethods.autoInit();
          }, 100);
        }
      });
  }

  /**
   * Dynamically determines the current route name based on the URL and updates the header title.
   * This method dynamically imports `SidebarNavItems` to reduce the initial bundle size and then
   * iterates through these items to find a match with the current URL. If a match is found, it updates
   * the header title with the corresponding route name and handles both top-level navigation items and their potential sub-items
   */
  private async setCurrentRouteName(): Promise<void> {
    const currentUrl = this.router.url;
    this.currentRouteName = '';

    const { SidebarNavItems } = await import('@dx-web/modules/shared/layouts');

    if (currentUrl === '/') {
      this.currentRouteName = SidebarNavItems[0].name;
      this.utilsService.headerTitleSignal.set(this.currentRouteName);
      return;
    }

    for (const item of SidebarNavItems) {
      if (item.routerLink !== '/' && currentUrl.includes(item.routerLink)) {
        this.currentRouteName = item.name;
        break;
      }

      // Check sub-items if any exist
      if (item.subItems) {
        for (const subItem of item.subItems) {
          if (currentUrl.includes(subItem.routerLink)) {
            this.currentRouteName = item.name;
            break;
          }
        }
      }
      if (this.currentRouteName) break;
    }

    // Update the header title with the current route name
    this.utilsService.headerTitleSignal.set(this.currentRouteName);
  }

  /**
   * Retrieves the user's theme preference from local storage.
   * @returns {boolean} True if the theme is light, false if dark.
   */
  private getTheme(): boolean {
    const lsTheme = localStorage.getItem('theme');
    return lsTheme !== 'dark';
  }

  /**
   * Applies the current theme by setting a class on the document body.
   * Also updates the theme preference in local storage.
   */
  private applyTheme() {
    const themeClass = this.isLightTheme ? 'light' : 'dark';
    this.renderer.setAttribute(this.document.body, 'class', themeClass);
    localStorage.setItem('theme', themeClass);
  }
}
