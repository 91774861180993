import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withRouterConfig } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import {
  authInterceptor,
  AuthService
} from '@dx-web/modules/shared/data-access';
import { icons } from '@dx-web/modules/shared/ui';
import { provideIcons } from '@ng-icons/core';
import {
  provideAngularQuery,
  QueryClient
} from '@tanstack/angular-query-experimental';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { appRoutes } from './app.routes';

export function tokenGetter() {
  const authService = inject(AuthService);
  return authService.getAuthToken();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({ onSameUrlNavigation: 'reload' })
    ),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          // TODO: update jwt config
          allowedDomains: ['example.com'],
          disallowedRoutes: ['http://example.com/examplebadroute/']
        }
      })
    ),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAnimationsAsync(),
    provideIcons(icons),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 3 // 3 minutes
          }
        }
      })
    ),
    provideZxvbnServiceForPSM()
  ]
};
